// Dependencies
import React, { FC, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Table as MuiTable,
  TableHead as MuiTableHead,
  Box,
  IconButton,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Avatar,
} from '@mui/material';

// Component
import { Dialog } from '../../../../components/Dialog';

// Styles
import styles from '../../styles';
import tableStyles from '../../../ManageWidgets/components/Widget/components/Table/styles';
import { BorderHorizontal, BorderVertical, DragIndicator, Settings } from '@mui/icons-material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import apis from '../../../../apis';
import { CellConfigDialog } from '../../../../components';

// Interfaces
interface IDataTableEditModalProps {
  title: string;
  open: boolean;
  data: any;
  loading: boolean;
  onClose: () => void;
  onSave: (updatedData: object) => void;
}

// Export DataTableEditModal
export const DataTableEditModal: FC<IDataTableEditModalProps> = ({ title, open, data, loading, onClose, onSave }) => {
  const [tableData, setTableData] = useState<any>({
    ...data,
    columns: data.columns || new Array(data.labels.length).fill({}),
  });
  const [columnSettingIndex, setColumnSettingIndex] = useState<number | null>(null);
  const [selectedPos, setSelectedPos] = useState<number[] | null>();
  const [selectedCellPos, setSelectedCellPos] = useState<[number, number] | null>(null);
  const fileSelector = useRef<any>();

  const submitForm = () => {
    onSave({
      ...tableData,
    });
  };

  const handleChangeCell = (rowIndex, colIndex, e, isObject) => {
    setTableData({
      ...tableData,
      values: tableData.values.map((row, rIndex) =>
        rIndex === rowIndex ? row.map((column, cIndex) => (cIndex === colIndex ? e.target.value : column)) : row
      ),
    });

    const values = tableData.values;
    setTableData({
      ...tableData,
      values: values.map((row, rIndex) =>
        rIndex === rowIndex
          ? row.map((column, cIndex) =>
              cIndex === colIndex ? (!isObject ? e.target.value : { ...column, text: e.target.value }) : column
            )
          : row
      ),
    });
  };

  const handleChangeLabel = (columnIndex, e) => {
    setTableData({
      ...tableData,
      labels: tableData.labels.map((cell, index) => (index === columnIndex ? e.target.value : cell)),
    });
  };

  const handleAddColumn = () => {
    setTableData({
      labels: [...tableData.labels, ''],
      values: tableData.values.map((row) => [...row, '']),
    });
  };

  const handleAddRow = () => {
    setTableData({
      ...tableData,
      values: [...tableData.values, tableData.labels.map(() => '')],
    });
  };

  const handleRemoveColumn = (colIndex: number) => {
    setTableData({
      ...tableData,
      labels: tableData.labels.filter((_, index) => index !== colIndex),
      values: tableData.values.map((row) => row.filter((_, index) => index !== colIndex)),
    });
  };

  const handleRemoveRow = (rowIndex: number) => {
    setTableData({
      ...tableData,
      values: tableData.values.filter((_, index) => index !== rowIndex),
    });
  };

  const handleOpenColumSetting = (column: number) => {
    setColumnSettingIndex(column + 1);
  };

  const handleCloseColumnSetting = () => {
    setColumnSettingIndex(null);
  };

  const handleChangeColumnSetting = (e) => {
    //
    setTableData({
      ...tableData,
      columns: (tableData.columns.length === 0 ? Array(tableData.values[0].length).fill('') : tableData.columns).map((col, index) =>
        columnSettingIndex && index === columnSettingIndex - 1 ? { ...col, type: e.target.value } : col
      ),
    });
  };

  const handleChangeValueConfigOption = (e) => {
    const values = tableData.values.map((row) =>
      row.map((cell, cellInd) => {
        return typeof cell === 'string' && cellInd === (columnSettingIndex || 0) - 1 && e.target.checked
          ? { fields: [{ type: 'text', table: '', field: '', valueType: 'string', text: cell }], isDynamic: true }
          : cell;
      })
    );
    //
    setTableData({
      ...tableData,
      columns: (tableData.columns.length === 0 ? Array(tableData.values[0].length).fill('') : tableData.columns).map((col, index) =>
        columnSettingIndex && index === columnSettingIndex - 1
          ? { ...col, showValueConfiguration: e.target.checked }
          : col
      ),
      values: values,
    });
  };

  const handleChangeAddToolTip = (e) => {
    setTableData({
      ...tableData,
      columns: (tableData.columns.length === 0 ? Array(tableData.values[0].length).fill('') : tableData.columns).map((col, index) =>
        columnSettingIndex && index === columnSettingIndex - 1
          ? { ...col, showColumnTooltip: e.target.checked, columnTooltip: '' }
          : col
      ),
    });
  };

  const handleChangeColumnTooltipText = (e) => {
    setTableData({
      ...tableData,
      columns: (tableData.columns.length === 0 ? Array(tableData.values[0].length).fill('') : tableData.columns).map((col, index) =>
        columnSettingIndex && index === columnSettingIndex - 1 ? { ...col, columnTooltip: e.target.value } : col
      ),
    });
  };

  const handleChangeLink = (rowIndex, columnIndex, e) => {
    const values = tableData.values;
    setTableData({
      ...tableData,
      values: values.map((row, rIndex) =>
        rIndex === rowIndex
          ? row.map((column, cIndex) =>
              cIndex === columnIndex
                ? {
                    linkUrl: column?.linkUrl || '',
                    linkText: column?.linkText || '',
                    [e.target.name]: e.target.value,
                  }
                : column
            )
          : row
      ),
    });
  };

  const handleOpenBrowser = (row, col) => {
    setSelectedPos([row, col]);
    fileSelector?.current?.click();
  };

  const handleUpdateLogo = async (e: any) => {
    if (!selectedPos) {
      return;
    }

    const [rowIndex, columnIndex] = selectedPos;
    if (e.target.files[0]) {
      apis.cdn.uploadImage(e.target.files[0]).then((res) => {
        if (res.status) {
          setTableData({
            ...tableData,
            values: tableData.values.map((row, rIndex) =>
              rIndex === rowIndex ? row.map((column, cIndex) => (cIndex === columnIndex ? res.fileName : column)) : row
            ),
          });
        }
      });
    }
  };

  const handleOpenCellConfigDialog = (row: number, col: number) => {
    setSelectedCellPos([row, col]);
  };

  const handleCloseCellConfigDialog = () => {
    setSelectedCellPos(null);
  };

  const handleSetCellConfig = (data: any) => {
    if (selectedCellPos) {
      const values = tableData.values;
      const emptyRow: any[] = [];
      setTableData({
        ...tableData,
        values:
          values.length > 0
            ? values.map((row, rIndex) =>
                rIndex === selectedCellPos[0]
                  ? row.map((column, cIndex) => (cIndex === selectedCellPos[1] ? data : column))
                  : row
              )
            : [[(emptyRow[selectedCellPos[1]] = data)]],
      });
    }
    setSelectedCellPos(null);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const arr = [...tableData.values];

    const removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);
    setTableData({ ...tableData, values: arr });
  };
  // Return ArrayEdit Modal
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={{ textTransform: 'uppercase' }}>{title.replace(/_/g, ' ')}</DialogTitle>
      <DialogContent sx={{ maxWidth: `calc(100vw - 330px)` }}>
        <Box display="flex" gap={10}>
          <Button onClick={handleAddColumn}>Add Column</Button>
          <Button onClick={handleAddRow}>Add Row</Button>
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`${tableData.title}-table`}>
            {(provided) => (
              <MuiTable sx={{ mt: 10 }} {...provided.droppableProps} ref={provided.innerRef}>
                <MuiTableHead sx={tableStyles.tableHeader}>
                  <MuiTableRow sx={tableStyles.tableRow}>
                    {tableData.labels.map((label, index) => (
                      <MuiTableCell key={`label-${index}`} sx={tableStyles.tableCell}>
                        <Box display="flex" alignItems="center" gap={8}>
                          <input placeholder="Label" value={label} onChange={(e) => handleChangeLabel(index, e)} />
                          <Tooltip title="Column Setting">
                            <IconButton sx={{ mr: 4 }} onClick={() => handleOpenColumSetting(index)}>
                              <Settings />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove Column">
                            <IconButton onClick={() => handleRemoveColumn(index)}>
                              <BorderVertical />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </MuiTableCell>
                    ))}
                  </MuiTableRow>
                </MuiTableHead>
                <MuiTableBody>
                  {tableData.values?.map((row, rowIndex) => (
                    <Draggable key={rowIndex} draggableId={`${tableData.title}-row-${rowIndex}`} index={rowIndex}>
                      {(provided, snapshot) => (
                        <MuiTableRow
                          sx={tableStyles.tableRow}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            background: snapshot.isDragging ? '#ecb6d680' : 'white',
                            ...(snapshot.isDragging && { display: 'flex' }),
                            ...provided.draggableProps.style,
                          }}
                        >
                          {row.map((cell, columnIndex) => (
                            <MuiTableCell
                              key={columnIndex}
                              sx={tableStyles.tableCell}
                              style={{ ...(snapshot.isDragging && { flex: 1, height: '100%' }) }}
                            >
                              <Box width="100%" display="flex" alignItems="center">
                                {columnIndex === 0 && (
                                  <Box sx={styles.dragIndicator} className="drag-handle" {...provided.dragHandleProps}>
                                    <DragIndicator />
                                  </Box>
                                )}
                                {tableData.columns &&
                                tableData.columns[columnIndex]?.type === 'text' &&
                                tableData.columns[columnIndex].showValueConfiguration &&
                                typeof cell !== 'string' ? (
                                  <Box sx={styles.configWrapper}>
                                    {cell.fields?.map((field, index) => (
                                      <Box key={index} sx={(theme) => styles.configField(theme, { type: field.type })}>
                                        <Typography variant="subtitle2">
                                          {field.type === 'text'
                                            ? field.text
                                            : `${field.table || '(table)'}.${field.field || '(field)'}`}
                                        </Typography>
                                      </Box>
                                    ))}
                                  </Box>
                                ) : (
                                  <input
                                    placeholder="Text"
                                    value={typeof cell === 'string' ? cell : cell.text}
                                    onChange={(e) =>
                                      handleChangeCell(rowIndex, columnIndex, e, typeof cell !== 'string')
                                    }
                                  />
                                )}
                                {tableData.columns && tableData.columns[columnIndex]?.type === 'link' && (
                                  <>
                                    <input
                                      placeholder="Text"
                                      name="linkText"
                                      value={cell?.linkText}
                                      onChange={(e) => handleChangeLink(rowIndex, columnIndex, e)}
                                    />
                                    <input
                                      placeholder="URL"
                                      name="linkUrl"
                                      value={cell?.linkUrl}
                                      onChange={(e) => handleChangeLink(rowIndex, columnIndex, e)}
                                    />
                                  </>
                                )}
                                {tableData.columns && tableData.columns[columnIndex]?.type === 'avatar' && (
                                  <>
                                    {cell && <Avatar sx={{ mr: 10 }} src={cell} />}
                                    <Button size="small" onClick={() => handleOpenBrowser(rowIndex, columnIndex)}>
                                      {cell ? 'Change' : 'Upload'}
                                    </Button>
                                    <input
                                      style={{ display: 'none' }}
                                      type="file"
                                      ref={fileSelector}
                                      onChange={handleUpdateLogo}
                                    />
                                  </>
                                )}
                                <Box display="flex" sx={{ ml: 'auto' }}>
                                  {tableData.columns && tableData.columns[columnIndex]?.showValueConfiguration && (
                                    <Tooltip title="Cell Config">
                                      <IconButton onClick={() => handleOpenCellConfigDialog(rowIndex, columnIndex)}>
                                        <Settings />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {columnIndex === 0 && (
                                    <Tooltip title="Remove Row">
                                      <IconButton onClick={() => handleRemoveRow(rowIndex)}>
                                        <BorderHorizontal />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </Box>
                            </MuiTableCell>
                          ))}
                        </MuiTableRow>
                      )}
                    </Draggable>
                  ))}
                </MuiTableBody>
              </MuiTable>
            )}
          </Droppable>
        </DragDropContext>

        {!!columnSettingIndex && (
          <Dialog open={!!columnSettingIndex} onClose={handleCloseColumnSetting} sx={styles.columnSettingsDialog}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ padding: '0 20px' }}>
                <Box display="flex" alignItems="center">
                  <Typography sx={{ mr: 10 }}>Type:</Typography>
                  <Select
                    sx={{ flexGrow: 1 }}
                    value={tableData.columns && tableData.columns[columnSettingIndex - 1]?.type}
                    onChange={handleChangeColumnSetting}
                  >
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="avatar">Avatar</MenuItem>
                    <MenuItem value="link">Link</MenuItem>
                  </Select>
                </Box>
                <Box sx={{ mt: 10, display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    label="Show value configuration"
                    sx={{ width: 'fit-content' }}
                    control={
                      <Switch
                        disabled={tableData.type === 'Automatic'}
                        checked={tableData.columns && tableData.columns[columnSettingIndex - 1]?.showValueConfiguration}
                        color="secondary"
                        onChange={handleChangeValueConfigOption}
                      />
                    }
                  />
                </Box>
                <Box sx={{ mt: 10, display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    label="Show Column Tooltip"
                    sx={{ width: 'fit-content' }}
                    control={
                      <Switch
                        checked={tableData.columns && tableData.columns[columnSettingIndex - 1]?.showColumnTooltip}
                        color="secondary"
                        onChange={handleChangeAddToolTip}
                      />
                    }
                  />
                  {tableData.columns && tableData.columns[columnSettingIndex - 1]?.showColumnTooltip && (
                    <TextField
                      label="Tooltip Text"
                      color="secondary"
                      multiline
                      value={tableData.columns[columnSettingIndex - 1]?.columnTooltip}
                      onChange={handleChangeColumnTooltipText}
                    />
                  )}
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleCloseColumnSetting}>Okay</Button>
              </Box>
            </Box>
          </Dialog>
        )}
        {selectedCellPos && (
          <CellConfigDialog
            isin={'test'}
            config={tableData?.values[selectedCellPos[0]][selectedCellPos[1]]}
            open={!!selectedCellPos}
            onClose={handleCloseCellConfigDialog}
            onConfirm={handleSetCellConfig}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={submitForm}>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
      </DialogActions>
    </Dialog>
  );
};
