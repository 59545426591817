// Dependencies
import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Grid, Pagination, Stack, Typography } from '@mui/material';
import moment from 'moment';

// Components
import { DocActions, NewDocDialog } from '../../components';

// Context
import { AppContext } from '../../context';

// Resource
import { DisplayMode } from '../../resource';

// Styles
import styles from './styles';
import {useToastContext} from '../../components/Toast/toastContext';

// Export home page
export const HomePage: FC = () => {
  const { activeToast } = useToastContext();
  // States
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(9);

  // Get context
  const {
    account,
    displayMode,
    dialogs,
    docTotalCount,
    documents,
    fetchDocuments,
    documentsLoading,
    showDeleted,
  } = useContext(AppContext);

  // Calc total page
  const totalPage = useMemo(() => {
    return Math.ceil(docTotalCount / perPage);
  }, [docTotalCount, perPage]);

  // Page change handler
  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const fetch = async () => {
    if (account?.id) {
      await fetchDocuments(account.id, currentPage, perPage, showDeleted, (msg?: string) => {
        activeToast({
          type: 'error',
          text: msg || 'Failed to fetch documents',
        });
      });
    }
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [showDeleted]);

  // On mounted
  useEffect(() => {
    fetch();
  }, [account?.id, perPage, currentPage, showDeleted]);

  // On display mode changed
  useEffect(() => {
    setCurrentPage(1);
    if (displayMode === DisplayMode.Module) {
      setPerPage(9);
    } else {
      setPerPage(15);
    }
  }, [displayMode]);

  // Return home page
  return (
    <>
      <Box sx={styles.container}>
        {documentsLoading ? (
          <CircularProgress />
        ) : documents?.length > 0 ? (
          <Stack width="100%" alignItems="center" spacing={{ xs: 48, md: 64, lg: 128 }}>
            {totalPage > 1 && (
              <Pagination page={currentPage} count={totalPage} siblingCount={0} onChange={handlePageChange} />
            )}
            {displayMode === DisplayMode.Module && (
              <Grid sx={styles.cardBox} container columns={12} spacing={{ xs: 32, md: 56, lg: 82 }}>
                {documents.map((doc, dIndex) => (
                  <Grid item xs={12} md={6} xl={4} key={dIndex}>
                    <Box sx={styles.card}>
                      <Box sx={styles.cardHeader}>
                        <Box>
                          <Typography variant="title">{doc.name}</Typography>
                          <Typography variant="body2">
                            {moment(doc.published_at).format('DD MMMM YYYY / hh:mm:ss')}
                          </Typography>
                        </Box>
                        <DocActions doc={doc} direction="vertical" archived={showDeleted} fetch={fetch} />
                      </Box>
                      <Box sx={styles.thumbnail}>
                        <img src={doc.thumbnail_url} alt={doc.thumbnail_url} />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
            {displayMode === DisplayMode.List && (
              <Stack width="100%" spacing={20}>
                {documents.map((doc, dIndex) => (
                  <Box key={dIndex} sx={styles.listItem}>
                    <Box>
                      <Typography variant="title">{doc.name}</Typography>
                      <Typography mt={12} variant="body2">
                        {moment(doc.createdAt).format('DD MMMM YYYY / hh:mm:ss')}
                      </Typography>
                    </Box>
                    <Box sx={styles.listAction}>
                      <DocActions doc={doc} archived={showDeleted} fetch={fetch} />
                    </Box>
                  </Box>
                ))}
              </Stack>
            )}
          </Stack>
        ) : (
          <Typography variant="title" align="center">
            No Document
          </Typography>
        )}
      </Box>
      {dialogs.newDoc.visible && <NewDocDialog open={dialogs.newDoc.visible} />}
    </>
  );
};
