export function hexToRgb(hex: string) {
  // Remove the hash if present
  hex = hex.replace('#', '');

  // Parse the hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b, a: 1 }; // Added alpha channel with value 1
}

export function rgbToHsv(r: number, g: number, b: number) {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const diff = max - min;

  let h = 0;
  const s = max === 0 ? 0 : (diff / max) * 100;
  const v = max * 100;

  if (diff !== 0) {
    if (max === r) {
      h = 60 * ((g - b) / diff);
    } else if (max === g) {
      h = 60 * (2 + (b - r) / diff);
    } else {
      h = 60 * (4 + (r - g) / diff);
    }
  }

  if (h < 0) {
    h += 360;
  }

  return { h: Math.round(h), s: Math.round(s), v: Math.round(v), a: 1 }; // Added alpha channel
}

export function hexToColor(hex: string) {
  const rgb = hexToRgb(hex);
  const hsv = rgbToHsv(rgb.r, rgb.g, rgb.b);

  return {
    hex: hex.startsWith('#') ? hex : `#${hex}`,
    rgb,
    hsv,
  };
}
