// Dependencies
import React, { FC, useState } from 'react';
import {
  Collapse,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './style.css';
import { ControversialLevelsChart } from './controversial-levels-chart';

// Menu list
const menuList = [
  'Ausschluss kontroverser Geschäftsfelder',
  'Ausschluss kontroverser Geschäftspraktiken',
  'ESG Dokumente',
  // 'ESG-RISIKO SCORE & COVERAGE',
  // 'ESG-DOKUMENTE',
  // 'ESG-RATING',
];

// Data
// const data1 = [
//   { a: 'Streumunition', b: '0.00 %' },
//   { a: 'Landminen', b: '0.00 %' },
//   { a: 'Uranmunition', b: '0.00 %' },
//   { a: 'Biologische und Chemische Waffen', b: '0.00 %' },
//   { a: 'Nuklearwaffen', b: '0.00 %' },
//   { a: 'Produktion ziviler Schusswaffen', b: '0.00 %' },
//   { a: 'Tabakherstellung', b: '0.00 %' },
//   { a: 'Kohle-/ Kernenergiegewinnung', b: '0.00 %' },
// ];

const data1 = [
  {
    icon: 'icon-tobacco',
    title: 'Produktion und Vertrieb von Tabakwaren',
    business_segment: '84%',
    conversion_lt_5: '16%',
    conversion_gt_5: '0%',
  },
  {
    icon: 'icon-spirits',
    title: 'Produktion von Spirituosen',
    business_segment: '96%',
    conversion_lt_5: '4%',
    conversion_gt_5: '0%',
  },
  {
    icon: 'icon-gambling',
    title: 'Betreiben von Glücksspieleinrichtungen',
    business_segment: '92%',
    conversion_lt_5: '8%',
    conversion_gt_5: '0%',
  },
  {
    icon: 'icon-armor',
    title: 'Hersetllung von Rüstungsgütern',
    business_segment: '80%',
    conversion_lt_5: '20%',
    conversion_gt_5: '0%',
  },
  {
    icon: 'icon-weapons',
    title: 'Hersetllung von völkerrechtlich geächteter Waffen',
    business_segment: '100%',
    conversion_lt_5: '0%',
    conversion_gt_5: '0%',
  },
  {
    icon: 'icon-oil-sands',
    title: 'Föderung von Ölsand',
    business_segment: '100%',
    conversion_lt_5: '0%',
    conversion_gt_5: '0%',
  },
  {
    icon: 'icon-Kohle',
    title: 'Gewinnung oder Verstromung von Kohle',
    business_segment: '100%',
    conversion_lt_5: '4%',
    conversion_gt_5: '0%',
  },
  {
    icon: 'icon-nuclear-energy',
    title: 'Erzeugung von Kernenergie',
    business_segment: '96%',
    conversion_lt_5: '4%',
    conversion_gt_5: '0%',
  },
  {
    icon: 'icon-fracking',
    title: 'Betreiben von Fracking',
    business_segment: '100%',
    conversion_lt_5: '0%',
    conversion_gt_5: '0%',
  },
  {
    icon: 'icon-graphy',
    title: 'Herstellung oder Vertrieb von Materials',
    business_segment: '88%',
    conversion_lt_5: '12%',
    conversion_gt_5: '0%',
  },
];

const data2 = [
  {
    a: 'Strategien für die Ausübung von Stimmrechten',
    b: 'https://www.shareholdervalue.de/hubfs/Fondsdokumente/LU2439874319/202004_Strategien_fuer_die_Ausuebung_von_Stimmrechten.pdf',
  },
  {
    a: 'Angaben zur Nachhaltigkeit gemäß Offenlegungsverordnung',
    b: 'https://www.shareholdervalue.de/hubfs/Fondsdokumente/LU2439874319/Angaben zur Nachhaltigkeit gemäß Offenlegungsverordnung.pdf',
  },
  {
    a: 'Dem verantwortungsvollen Investment verpflichtet',
    b: 'https://www.shareholdervalue.de/hubfs/Fondsdokumente/LU2439874319/2021.06_Verantwortungsvolles_Investment_SVM.pdf',
  },
  {
    a: 'Mitwirkungspolitik',
    b: 'https://www.shareholdervalue.de/hubfs/Fondsdokumente/LU2439874319/202106_Mitwirkungspolitik.pdf',
  },
  {
    a: 'Jährliche Veröffentlichung 2021 zur Umsetzung der Mitwirkungspolitik',
    b: 'https://www.shareholdervalue.de/hubfs/Fondsdokumente/LU2439874319/Auswertung_HVs_2021.pdf',
  },
];

const data3 = [
  { a: 'ESG Risk Score', b: '16,49' },
  { a: 'ESG Risk Score Coverage', b: '81,33 %' },
];

const data4 = [
  'Bericht zur Stimmrechtsausübung	de_DE',
  'UN PRI Transparenzbericht	en_GB',
  'Erklärung zu nachteiligen Nachhaltigkeitsauswirkungen „Principal Adverse Impacts, PAI-Statement“	de_DE',
  'Stimmrechtepolitik	de_DE',
  'Nachhaltigkeitsbezogene Offenlegungen	de_DE en_GB',
  'Vergütungspolitik	de_DE',
  'Asset Management - Richtlinie und Grundsätze für nachhaltigie Investments	de_DE',
  'Nachhaltigkeitsbericht	de_DE',
  'FNG-Nachhaltigkeitsprofil	de_DE',
  'Eurosif - Europäischer SRI Transparenz Kodex',
];

const data5 = ['Morningstar Sustainbility Rating'];

// Export esg panel
export const ESGPanel: FC = () => {
  // States
  const [collapseStatus, setCollapseStatus] = useState([false, false, false, false]);

  // Toggle collapse handler
  const handleToggleCollapse = (index: number) => {
    collapseStatus[index] = !collapseStatus[index];
    setCollapseStatus([...collapseStatus]);
  };

  // Return esg panel
  return (
    <>
      {menuList.map((menu, index) => (
        <React.Fragment key={index}>
          <MenuItem className="_MenuItem" onClick={() => handleToggleCollapse(index)}>
            <div className="MenuItem_inner">
              {menu}
              {collapseStatus[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
          </MenuItem>
          <Collapse in={collapseStatus[index]}>
            {index === 0 && (
              <>
                <List className="scroll-mobile controversial_business_areas">
                  <ListItem className="list_heading_style" key={index}>
                    <ListItemAvatar>
                      <span></span>
                    </ListItemAvatar>
                    <ListItemText className="title">Ausschlusskriterien</ListItemText>
                    <ListItemText sx={{ textAlign: 'right' }}>
                      <div className="Tooltip_wrapper">
                        Kein Umsatz im Geschäftsfeld
                        <div className="Tooltip">
                          <InfoSharpIcon />
                          <div className="Tooltip-content">
                            Die Prozentzahlen in dieser Spalte geben den Anteil der Unternehmen im Portfolio an, die gar
                            keinen Umsatz in dem jeweiligen Geschäftsfeld erzielen.
                          </div>
                        </div>
                      </div>
                    </ListItemText>
                    <ListItemText sx={{ textAlign: 'right' }}>
                      <div className="Tooltip_wrapper">
                        Umsatz &lt;5%
                        <div className="Tooltip">
                          <div className="Tooltip-content">
                            Die Prozentzahlen in dieser Spalte geben den Anteil der Unternehmen an, die zwischen 0% und
                            4,9% ihrer Umsätze in dem jeweiligen Geschäftsfeld erzielen. 4,9% des Umsatzes in einem
                            Geschäftsfeld, ist der maximal zulässige Anteil, der unter dem Gesichtspunkt der
                            Verhältnismäßigkeit toleriert wird. Das gilt nicht für geächtete Waffen, Ölsand und Fracking
                            - in diesen Bereichen greift ein absolutes Investmentverbot.
                          </div>
                          <InfoSharpIcon />
                        </div>
                      </div>
                    </ListItemText>
                    <ListItemText sx={{ textAlign: 'right' }}>
                      <div className="Tooltip_wrapper">
                        Umsatz &gt;5%
                        <div className="Tooltip">
                          <div className="Tooltip-content">
                            Die Prozentzahlen in dieser Spalte geben den Anteil der Unternehmen an, die mehr als 5%
                            ihrer Umsätze in dem jeweiligen Geschäftsfeld erzielen. Mehr als 5% ist nach unseren
                            Kriterien nicht zulässig.
                          </div>
                          <InfoSharpIcon />
                        </div>
                      </div>
                    </ListItemText>
                  </ListItem>
                  {data1.map(({ icon, title, business_segment, conversion_lt_5, conversion_gt_5 }, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <span className={`${icon}`}></span>
                      </ListItemAvatar>
                      <ListItemText className="title">{title}</ListItemText>
                      <ListItemText sx={{ textAlign: 'right' }}>{business_segment}</ListItemText>
                      <ListItemText sx={{ textAlign: 'right' }}>{conversion_lt_5}</ListItemText>
                      <ListItemText sx={{ textAlign: 'right' }}>{conversion_gt_5}</ListItemText>
                    </ListItem>
                  ))}
                </List>
                <Stack mt={5} spacing={5} divider={<Divider />}>
                  <Typography>Diese Werte können sich im Laufe der Zeit ändern.</Typography>
                  <Typography>
                    Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: 30.06.2022
                  </Typography>
                </Stack>
              </>
            )}
            {index === 1 && (
              <Stack mt={5} spacing={5} divider={<Divider />}>
                <ControversialLevelsChart />
                <Typography>
                  <Link target={`_blank`} href="https://www.sustainalytics.com/" underline="hover">
                    {' '}
                    Sustainalytics{' '}
                  </Link>{' '}
                  stuft alle kontroversen Zwischenfälle, die in Verbindung mit Firmen gebracht werden, in 5 Level ein.
                  Je höher das Level, desto bedrohlicher der Zwischenfall für das operative Geschäft des Unternehmens.
                  Darunter fallen Betrugsvorwürfe wie Steuerhinterziehung, Korruptionsverdacht oder Verletzung der
                  Arbeits- oder Menschenrechte. Kritische Zwischenfälle werden mit Level 5 bewertet. In Level 5
                  Unternehmen investieren wir nicht. Bestandspositoinen, die auf Level 5 hochgestuft werden, verkaufen
                  wir. Diese Werte können sich im Laufe der Zeit ändern.
                </Typography>
                <Typography>
                  Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: 30.06.2022
                </Typography>
              </Stack>
            )}
            {index === 2 && (
              <>
                <List className="pdf_table">
                  {data2.map(({ a, b }, index) => (
                    <ListItem className="pdf_table_row" key={index}>
                      <ListItemText className="title">
                        <Typography>{a}</Typography>
                      </ListItemText>
                      <ListItemText className="pdf">
                        <a href={b} target="_blank">
                          <img src="https://mainfirst.tools.factsheetslive.com/bundles/mainfirsttool/images/lc_pdf_icon_DE.png" />
                        </a>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {index === 3 && (
              <>
                <List>
                  {data3.map(({ a, b }, index) => (
                    <ListItem key={index}>
                      <ListItemText>{a}</ListItemText>
                      <ListItemText sx={{ textAlign: 'right' }}>
                        <a href={b} target="_blank">
                          <img src="https://mainfirst.tools.factsheetslive.com/bundles/mainfirsttool/images/lc_pdf_icon_DE.png" />
                        </a>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {index === 3 && (
              <>
                <List>
                  {data3.map(({ a, b }, index) => (
                    <ListItem key={index}>
                      <ListItemText>{a}</ListItemText>
                      <ListItemText sx={{ textAlign: 'right' }}>{b}</ListItemText>
                    </ListItem>
                  ))}
                </List>
                <Stack mt={5} spacing={5} divider={<Divider />}>
                  <Typography>Diese Werte können sich im Laufe der Zeit ändern.</Typography>
                  <Typography>
                    Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: 30.06.2022
                  </Typography>
                </Stack>
              </>
            )}
            {index === 4 && (
              <Table sx={{ marginY: 10 }}>
                <TableBody>
                  {data4.map((d, index) => (
                    <TableRow key={index}>
                      <TableCell>{d}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {index === 5 && (
              <>
                <Table>
                  <TableBody>
                    {data5.map((d, index) => (
                      <TableRow key={index}>
                        <TableCell>{d}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Typography>
                  © 2022 Morningstar. Alle Rechte vorbehalten. Weitere detailliertere Informationen über das Morningstar
                  Sustainability Rating*, auch über seine Methodik, finden Sie auf: https://bit.ly/3078bQt. Aus 3038 EUR
                  Flexible Allocation - Global Fonds, Stand: 31.03.22. Basierend auf 93,73% der AUM von „Unternehmen“
                  und 100,00% der AUM von „Staaten“. Die Daten beruhen nur auf Long-Positionen.
                </Typography>
              </>
            )}
          </Collapse>
        </React.Fragment>
      ))}
    </>
  );
};
