// Dependencies
import React, { FC } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Box, CircularProgress } from '@mui/material';

export const ApexLineChart: FC<{ data: any, isSolActive: boolean, color?: string, loading?: boolean }> = ({ data, isSolActive, color, loading }) => {
// BarChart option
  const options: ApexOptions = {
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'bottom',
    },
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: color || '#003F7A',
          label: {
            text: 'Support',
            style: {
              color: '#fff',
              background: '#00E396',
            },
          },
        },
      ],
      xaxis: [
        {
          borderColor: color || '#003F7A',
          label: {
            text: 'Rally',
            style: {
              color: '#fff',
              background: color || '#003F7A',
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 6,
    },
    yaxis: {
      labels: {
        formatter: (value) => value.toFixed(0),
      },
    },
    tooltip: {
      x: {
        format: 'MMM dd yyyy',
      },
    },
    colors: [color || '#003F7A'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#00000008'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ReactApexChart
        options={options}
        series={[
          {
            name: 'Frankfurter Modern Value Index',
            data: isSolActive ? data : data?.map((history: any) => [history.timestamp, history.value]) || [],
          },
        ]}
        type="area"
        height={350}
      />
    </Box>
  );
};
