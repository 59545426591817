// Dependencies
import { FC } from 'react';
import {
  Box,
  ButtonBase,
  Grid,
  Typography
} from '@mui/material';
import { ExpandCircleDown, ShortText, Widgets, FormatColorText, TableRows, SsidChart, ViewModule } from '@mui/icons-material';
import { Description } from '@mui/icons-material';

// Components
import { Dialog } from '../../Dialog';

// Styles
import styles from './styles';

import {
  Chart5Icon,
  Chart10Icon,
  Chart12Icon,
} from '../../../assets/icons';

// Constants
const contentItems = [
  {
    icon: <ExpandCircleDown />,
    type: 'collapse',
    label: 'Collapse',
    content: [],
  },
  {
    icon: <ShortText />,
    type: 'text-row',
    label: 'Text Row',
    content: "",
  },
  {
    icon: <Chart5Icon />,
    type: 'bar-chart',
    label: 'Bar Chart',
    content: { categories: [], chartData: [] },
  },
  {
    icon: <Chart12Icon />,
    type: 'pie-chart',
    label: 'Pie Chart',
    content: { showBlocks: false, chartData: [] },
  },
  {
    icon: <ViewModule />,
    type: 'block',
    label: 'Blocks',
    content: { title: '', subtitle: '', isDynamic: false, data: [] },
  },
  {
    icon: <Chart10Icon />,
    type: 'history',
    label: 'History Table',
    content: { text: '', title: '', isPercent: false },
  },
  {
    icon: <Description />,
    type: 'document-list',
    label: 'Documents',
    content: [],
  },
  {
    icon: <TableRows />,
    type: 'table',
    label: 'Table',
    content: { themeIndex: 0, header: [''], body: [['']], columns: [{ type: 'text' }] },
  },
  {
    icon: <FormatColorText />,
    type: 'rich-text',
    label: 'Rich Text',
    content: '{"blocks":[{"key":"f1gmg","text":"This is the new value of the editor","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  }
];

interface IWidgetListDialog {
  open: boolean;
  onClose: () => void;
  onSelect: (value: any) => void;
}

// Export ESG Geschäftsfelder doc dialog
export const WidgetListDialog: FC<IWidgetListDialog> = ({ open, onClose, onSelect }) => {
  const handleSelect = (item) => {
    onSelect({
      id: +new Date(),
      type: item.type,
      content: item.content,
    });
    onClose();
  };

  // Return customer doc dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Box sx={styles.form}>
        <Grid container columns={3} rowSpacing={20}>
          {contentItems.map((item, index) => (
            <Grid key={index} item xs={1}>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <ButtonBase sx={styles.chart} onClick={() => handleSelect(item)}>{item.icon}</ButtonBase>
                <Typography variant="subtitle2">{item.label}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  );
};
