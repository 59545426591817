// Resources
import { IStyles } from '../../../../../../resource';

// Define styles
const styles: IStyles = {
  areaDate: (theme) => ({
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }),
  placeholder: (theme) => ({
    marginTop: theme.spacing(20),
    color: theme.palette.gray.light,
  }),
  cellConfigInput: (theme) => ({
    boxSizing: 'border-box',
    border: '1px solid #BF0A76',
    height: '46px',
    borderRadius: '23px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 12px',
    transition: 'background-color 0.3s ease',
    '.MuiIconButton-root': {
        width: '32px',
        height: '32px',
        marginLeft: '8px',
        opacity: '0.5',
        '&:hover': {
          opacity: '1',
        },
      },
    "&:hover": {
      backgroundColor: 'rgba(191, 10, 118, 0.3)',
    }
  }),
  configField: (theme, { type }) => ({
    color: theme.palette.common.white,
    width: 'fit-content',
    backgroundColor: type === 'text' ? theme.palette.grey['200'] : theme.palette.success['light'],
    padding: theme.spacing(4, 8),
    borderRadius: theme.spacing(8),
    cursor: 'pointer',
    border: '1px solid transparent',
  }),
  rowCountItem: (theme, {isFirst, isLast, active}) => ({
    border: `2px solid ${theme.palette.secondary.main}`,
    fontSize: '24px',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderLeft: !isFirst ? 0 : `2px solid ${theme.palette.secondary.main}`,
    borderTopLeftRadius: isFirst ? '4px' : 0,
    borderBottomLeftRadius: isFirst ? '4px' : 0,
    borderTopRightRadius: isLast ? '4px' : 0,
    borderBottomRightRadius: isLast ? '4px' : 0,
    cursor: 'pointer',
    backgroundColor: active ? theme.palette.secondary.main : 'white',
    color: active ?  'white' : theme.palette.secondary.main,
    fontFamily: 'Changa',
    "&:hover": {
      background: theme.palette.secondary.light,
      color: 'white',
    }
  }),
  colorPickerItem: (theme, {isFirst, isFirstCellOnFirstRow, isFirstCellOnLastRow, isLastCellOnFirstRow, isLastCellOnLastRow, isFirstRow}) => ({
    width: '40px',
    height: '40px',
    border: `2px solid ${theme.palette.secondary.main}`,
    fontSize: '24px',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderLeft: !isFirst ? 0 : `2px solid ${theme.palette.secondary.main}`,
    borderTop: !isFirstRow ? 0 : `2px solid ${theme.palette.secondary.main}`,
    borderTopLeftRadius: isFirstCellOnFirstRow ? '4px' : 0,
    borderBottomLeftRadius: isFirstCellOnLastRow ? '4px' : 0,
    borderTopRightRadius: isLastCellOnFirstRow ? '4px' : 0,
    borderBottomRightRadius: isLastCellOnLastRow ? '4px' : 0,

    cursor: 'pointer',
    // backgroundColor: active ? theme.palette.secondary.main : 'white',
    fontFamily: 'Changa',
    "&:hover": {
      background: theme.palette.secondary.light,
      color: 'white',
    }
  }),
};

// Export styles
export default styles;
