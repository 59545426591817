// Dependencies
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
  Link,
  Button,
  ListItemAvatar,
  Tooltip,
} from '@mui/material';
import { Check, Close, Settings } from '@mui/icons-material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// Components
import { Chart, DataTable, Header, Label } from './components';

// Modals
import { ArrayEditModal, ObjectEditModal, TextEditModal, LogosEditModal } from './modals';

// Services
import { customizeDocument, getDocument, getDocumentFields, previewDocument } from '../../apis/pdf.apis';

// Styles
import styles from './styles';
import { ApexLineChart } from './components/ApexChart';
import { AppContext } from '../../context';
import { API_SERVER, ROUTES } from '../../contants';
import { BusinessTableEditModal } from './modals/BusinessTableEdit';
import { ContactEditModal } from './modals/ContactEdit';
import apis from '../../apis';
import { DataTableEditModal } from './modals/DataTableEditModal';
import { PreviewDocDialog } from '../../components';
import { getCdnWidgetByIsin } from '../../apis/cdn.apis';
import { client } from '../../App';
import { GET_AUTOMATED_COLUMNS } from '../../queries/widgets';
import { LabelEditor } from '../../components/LabelEditor';
import { ImageEditor } from '../../components/ImageEditor';

// Interfaces
export enum EditType {
  Text = 'text',
  Date = 'date',
  Object = 'object',
  Array = 'array',
  KeyValueArray = 'keyValueArray',
  Intro = 'intro',
  Logo = 'logo',
  Table = 'table',
  Contact = 'contact',
  DataTable = 'dataTable',
}

export const businessTableData = [
  ['./public/icon_1.png', 'Produktion und Vertrieb von Tabakwaren', '84%', '14%'],
  ['./public/icon_8.png', 'Betreiben von Fracking', '100%', '0%'],
  ['./public/icon_5.png', 'Betreiben von Glücksspieleinrichtungen', '96%', '4%'],
  ['./public/icon_4.png', 'Gewinnung oder Verstromung von Kohle', '100%', '4%'],
  ['./public/icon_10.png', 'Herstellung oder Vertrieb von Materials', '92%', '8%'],
  ['./public/icon_2.png', 'Förderung von Ölsand', '96%', '4%'],
  ['./public/icon_7.png', 'Herstellung von Rüstungsgütern', '80%', '20%'],
  ['./public/icon_3.png', 'Produktion von Spirituosen', '100%', '0%'],
  ['./public/icon_6.png', 'Erzeugung von Kernenergie', '100%', '0%'],
  ['./public/icon_9.png', 'Herstellung von völkerrechtlich geächteter Waffen', '88%', '12%'],
];

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});


// Export PDF Viewer
export const PDFViewer: FC = () => {
  // States
  const [pdf, setPdf] = useState<any>();
  const [editField, setEditField] = useState<string>('');
  const [editFieldId, setEditFieldId] = useState<string>('');
  const [visibleEditModal, setVisibleEditModal] = useState<EditType | null>(null);
  const [customizeLoading, setCustomizeLoading] = useState(false);
  const [lineChartData, setLineChartData] = useState<any>([]);
  const [pdfTitle, setPdfTitle] = useState<string>('');
  const [titleActive, setTitleActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editedFields, setEditedFields] = useState<any[]>([]);
  const [previewPdf, setPreviewPdf] = useState<string | null>(null);
  const [isHistoryChartLoading, setIsHistoryChartLoading] = useState(false);

  // Get params from hook
  const { id } = useParams<{ id: string }>();

  const { account, document, setDocument } = useContext(AppContext);

  const navigate = useNavigate();

  const fetchAutomatedColumns = async (columns: any[], isin: string) =>
    client.query({
      query: GET_AUTOMATED_COLUMNS,
      variables: { param: { data: columns, isin } },
    });

  const fetchHistoryChart = (docRes, pdfRes, until = '') => {
    setIsHistoryChartLoading(true)
    apis.cdn.getHistoryChartData(
      docRes?.template?.isins?.length ? 'Axxion' : 'SolActive',
      docRes?.template?.isins[0],
      'all',
      until || pdfRes.wertentwicklung_seit_auflage_end_date
    ).then((res) => {
      setLineChartData(res.histories);
    }).finally(() => {
      setIsHistoryChartLoading(false);
    });
  };

  // Fetch PDF
  const fetchDocument = async () => {
    if (id) {
      try {
        setLoading(true);
        const res: any = await getDocumentFields(id);
        setLoading(false);

        if (res) {
          setPdf(res.fields);
          setPdfTitle(res.fields.title || '');
        }

        const docRes = await getDocument(account?.id, id);
        setDocument(docRes);

        if (docRes?.template?.isins?.length) {
          fetchHistoryChart(docRes, res.fields);
        } else {
          setLineChartData(res.wertentwicklung_seit_auflage);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  // Close handler
  const handleClose = () => {
    setVisibleEditModal(null);
  };

  // Save handler
  const handleSave = (updatedData: object | string, key?: string) => {
    if (id) {
      setEditedFields([
        ...editedFields,
        {
          key: key || editField,
          value: updatedData,
        },
        ...(editField === 'published_at' ? [{
          key: 'wertentwicklung_seit_auflage_end_date',
          value: updatedData,
        }] : [])
      ]);
      setPdf({
        ...pdf,
        [key || editField]: updatedData,
        ...(editField === 'published_at' && {
          'wertentwicklung_seit_auflage_end_date': updatedData,
        })
      });

      if (editField === 'published_at' || editField === 'wertentwicklung_seit_auflage_end_date') {
        fetchHistoryChart(document, pdf, updatedData as string);
      }

      handleClose();
    }
  };

  const handleSaveTitle = (key: string, value: string) => {
    if (id) {
      setEditedFields([
        ...editedFields,
        {
          key: 'headings',
          value: {
            ...(pdf.headings || {}),
            [key]: value,
          },
        },
      ]);
      setPdf({
        ...pdf,
        headings: {
          ...(pdf.headings || {}),
          [key]: value,
        },
      });
    }
  };

  const handleSync = () => {
    getCdnWidgetByIsin(document?.template.isins[0]).then(async (res) => {
      const syncCells: any[] = [];
      let syncBarChartData: any = {};
      let syncPieChartData: any = {};
      let syncTableData: any[] = [];
      res.tabs.forEach((tab) => {
        tab.content?.forEach((widget) => {
          if (widget?.type === 'table') {
            if (widget.content.isLinkedWithPdfField) {
              syncTableData.push(widget);
            } else {
              widget.content.body.forEach((row) => {
                row.forEach((cell) => cell.isLinkWithPdfField && syncCells.push(cell));
              });
            }
          }

          if (widget?.type === 'bar-chart' && widget.content.pdfField) {
            const chartData = widget.content.categories.map((key, index) => {
              return { label: key, value: widget.content.chartData?.[0]?.data?.[index] };
            });
            syncBarChartData = {
              ...syncBarChartData,
              [widget.content.pdfField]: chartData,
            };
          }

          if (widget?.type === 'pie-chart' && widget.content.pdfField) {
            const chartData = widget.content.chartData.map((item) => ({
              label: item.label,
              value: +item.value,
              color: document?.template?.color || '#165B9D',
            }));
            syncPieChartData = {
              ...syncPieChartData,
              [widget.content.pdfField]: chartData,
            };
          }
        });
      });

      const syncedCellData = await Promise.all(
        syncCells.map(async (syncedCell) => {
          if (syncedCell.isDynamic) {
            const res = await fetchAutomatedColumns([syncedCell], document?.template.isins[0]);
            return {
              ...syncedCell.pdfField,
              value: res.data.getAutomatedColumns?.[0]?.[0],
            };
          } else {
            return {
              ...syncedCell.pdfField,
              value: syncedCell,
            };
          }
        })
      );

      syncTableData = await Promise.all(
        syncTableData.map(async (table) => {
          const body = await Promise.all(
            table.content.body.map(async (row) => {
              return await Promise.all(
                row.map(async (cell) => {
                  if (cell.isDynamic) {
                    const res = await fetchAutomatedColumns([cell], document?.template.isins[0]);
                    return res.data.getAutomatedColumns?.[0]?.[0];
                  } else {
                    return cell;
                  }
                })
              );
            })
          );
          return {
            body,
            header: table.content.header,
            key: table.content.pdfTableKey,
          };
        })
      );

      syncTableData = syncTableData.reduce((acc, tableData) => {
        const oldData = pdf[tableData.key];
        let data;
        if (Array.isArray(oldData)) {
          data = [];
          tableData.header.map((item, index) => {
            data.push({
              label: item,
              value: tableData.body[0][index],
              labelWidth: oldData[index]?.labelWidth,
              width: oldData[index]?.width,
            });
          });
        } else {
          data = {
            labels: tableData.header,
            values: tableData.body,
            widths: oldData.widths,
          };
        }

        return {
          ...acc,
          [tableData.key]: data,
        };
      }, {});

      const syncedFields = syncedCellData.reduce((acc, cell) => {
        let tableData = acc.find((item) => item.key === cell.table);
        if (tableData) {
          tableData = {
            key: cell.table,
            value: {
              ...tableData.value,
              [cell.cell]: cell.value,
            },
          };
          setPdf((prev) => ({
            ...prev,
            [cell.table]: tableData.value,
          }));
          return acc.map((item) => (item.key === cell.table ? tableData : item));
        } else {
          acc.push({
            key: cell.table,
            value: {
              ...pdf[cell.table],
              [cell.cell]: cell.value,
            },
          });
          setPdf((prev) => ({
            ...prev,
            [cell.table]: {
              ...prev[cell.table],
              [cell.cell]: cell.value,
            },
          }));
          return acc;
        }
      }, []);

      setPdf((prev) => ({ ...prev, ...syncBarChartData, ...syncPieChartData, ...syncTableData }));
      setEditedFields([
        ...syncedFields,
        ...Object.keys(syncBarChartData).map((key) => ({ key, value: syncBarChartData[key] })),
        ...Object.keys(syncPieChartData).map((key) => ({ key, value: syncPieChartData[key] })),
        ...Object.keys(syncTableData).map((key) => ({ key, value: syncTableData[key] })),
      ]);
    });
  };

  const handleSaveChanges = () => {
    if (id && editedFields.length) {
      setLoading(true);
      setCustomizeLoading(true);
      handleClose();
      customizeDocument(account?.id, id, editedFields)
        .then(async () => {
          setCustomizeLoading(false);
          setTitleActive(false);
          handleClose();
          await fetchDocument();
          setLoading(false);
          setEditedFields([]);
        })
        .catch(() => {
          setCustomizeLoading(false);
          setLoading(false);
        });
    }
  };

  const handlePreview = () => {
    if (id) {
      if (editedFields.length) {
        setLoading(true);
        setCustomizeLoading(true);
        handleClose();
        previewDocument(account?.id, id, editedFields)
          .then(async (data) => {
            setCustomizeLoading(false);
            setTitleActive(false);
            setLoading(false);
            setPreviewPdf(data?.file_url);
          })
          .catch(() => {
            setCustomizeLoading(false);
            setLoading(false);
          });
      } else {
        setPreviewPdf(document?.file_url);
      }
    }
  };

  const handleClosePreviewModal = () => {
    setPreviewPdf(null);
  };

  // Contact Save handler
  const handleSaveContact = (updatedData: any) => {
    if (id) {
      setEditedFields([...editedFields, { key: 'contact_fields', value: updatedData }]);
      setPdf({
        ...pdf,
        contact_fields: pdf.contact_fields.map((contact) => (contact._id === updatedData._id ? updatedData : contact)),
      });
      handleClose();
      return;
    }
  };

  // Editable click handler
  const handleEditableItemClick = (editField: string, type: EditType, id?: string) => () => {
    setEditField(editField);
    setVisibleEditModal(type);
    if (id) {
      setEditFieldId(id);
    }
  };

  // Handle pdf title
  const handleChangePdfTitle = (e) => {
    setPdfTitle(e.target.value);
  };

  // Handle update title
  const handleUpdateTitle = async () => {
    if (id) {
      setLoading(true);
      setCustomizeLoading(true);
      handleClose();
      setTitleActive(false);
      customizeDocument(account?.id, id, [{ key: 'title', value: pdfTitle }])
        .then(async () => {
          setCustomizeLoading(false);
          handleClose();
          await fetchDocument();
          setLoading(false);
          setEditedFields([]);
        })
        .catch(() => {
          setCustomizeLoading(false);
          setLoading(false);
        });
    }
  };

  // Handle cancel update title
  const handleCancelUpdateTitle = async () => {
    setTitleActive(false);
    setPdfTitle(pdf?.title);
  };

  // Handle toggle title active
  const handleTitleActive = (e) => {
    e.preventDefault();
    setTitleActive(true);
  };

  const handleBack = () => {
    navigate(ROUTES.DASHBOARD);
  };

  // Get business table field
  const getValueByLabel = (rowIndex, index) => {
    const value = pdf?.business_table[rowIndex];
    if (!value) return 0;
    return `${index === 0 ? value.no_turnover : value.sales_volume}%`;
  };

  // Get edit data
  const editData = useMemo(() => {
    if (editField) {
      return pdf[editField];
    } else {
      return '';
    }
  }, [editField, pdf]);

  const getEsgItemImage = (item, index) => {
    if (item.image) {
      return item.image.includes('./public') ? `${API_SERVER}/${item.image}` : item.image;
    } else {
      return businessTableData[index] ? `${API_SERVER}/${businessTableData[index]?.[0]}` : '';
    }
  };

  // On id changed
  useEffect(() => {
    (async () => {
      await fetchDocument();
    })();
  }, [id]);

  // Return PDF Viewer
  return (
    <>
      <Backdrop open={loading} sx={{ mt: 110, zIndex: 1 }}>
        <CircularProgress />
      </Backdrop>

      {!!pdf && (
        <Stack spacing={20}>
          <Box>
            <Box
              position="fixed"
              width="calc(100% - 780px)"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={10}
              top="28px"
              zIndex={10}
            >
              <Box sx={(theme) => styles.title(theme, { active: titleActive })}>
                <input
                  value={pdfTitle}
                  onChange={handleChangePdfTitle}
                  onClick={handleTitleActive}
                  placeholder="No Title"
                />
                <Stack direction="row" justifyContent="flex-end" mt={8} display={titleActive ? 'flex' : 'none'}>
                  <IconButton sx={{ mr: 8, width: 32, height: 32 }} onClick={handleUpdateTitle}>
                    <Check />
                  </IconButton>
                  <IconButton sx={{ width: 32, height: 32 }} size="small" onClick={handleCancelUpdateTitle}>
                    <Close />
                  </IconButton>
                </Stack>
              </Box>
            </Box>
            <Box sx={styles.actions}>
              <Button disabled={!editedFields.length} onClick={handleSaveChanges}>
                Save
              </Button>
              <Button onClick={handleSync}>Synchronize</Button>
              <Button onClick={handlePreview}>Preview</Button>
              <Button onClick={handleBack}>Cancel</Button>
            </Box>
          </Box>

          <Card sx={styles.paper}>
            <Typography color="#949599" fontSize={24}>
              Werbematerial, kein investmentrechtliches Pfichtdokument
            </Typography>
            <Header pdf={pdf} editableItemClick={handleEditableItemClick} template={document?.template} />
            <Box sx={styles.intro} onClick={handleEditableItemClick('intro', EditType.Intro)}>
              <Grid container spacing={20} sx={{ pb: 20 }}>
                <Grid item xs={2}>
                  <Box sx={styles.introImage}>
                    <ImageEditor
                      value={document?.template?.manImageUrl || '/images/intro.png'}
                      onChange={(value) => handleSave(value, 'manImageUrl')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Typography align="justify" color="#000000" fontSize={20} fontWeight={600}>
                    {pdf?.intro[0]}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography align="justify" color="#000000" fontSize={20} fontWeight={600}>
                    {pdf?.intro[1]}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={50}>
              <Grid item xs={5}>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('investmentphilosophie', EditType.Text)}
                  >
                    INVESTMENTPHILOSOPHIE
                  </Label>
                  <Typography color="#000" fontSize={20} align="justify">
                    {pdf?.investmentphilosophie}
                  </Typography>
                </Box>
                <Box sx={styles.widget}>
                  <Label color={document?.template?.color} onClick={handleEditableItemClick('preise', EditType.Object)}>
                    PREISE
                  </Label>
                  <DataTable data={pdf?.preise} />
                </Box>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('konditionen', EditType.Object)}
                  >
                    KONDITIONEN
                  </Label>
                  <DataTable data={pdf?.konditionen} />
                </Box>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('stammdaten', EditType.Object)}
                  >
                    STAMMDATEN
                  </Label>
                  <DataTable data={pdf?.stammdaten} />
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('wertentwicklung_seit_auflage_end_date', EditType.Date)}
                  >
                    WERTENTWICKLUNG SEIT AUFLAGE
                  </Label>
                  <ApexLineChart
                    loading={isHistoryChartLoading}
                    isSolActive={document?.template?.isins?.length}
                    data={lineChartData}
                    color={document?.template?.color}
                  />
                </Box>
                <Box sx={styles.widget}>
                  <Typography
                    color="#000"
                    sx={(theme) => styles.chartsLabel(theme, document?.template?.color)}
                    onClick={handleEditableItemClick('wertentwicklung_seit_auflage_label', EditType.Text)}
                  >
                    <span>-</span>
                    {pdf?.wertentwicklung_seit_auflage_label}
                  </Typography>
                </Box>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('wertentwicklung_nach_jahren', EditType.KeyValueArray)}
                  >
                    WERTENTWICKLUNG NACH JAHREN (%)
                  </Label>
                  <Chart
                    data={pdf?.wertentwicklung_nach_jahren}
                    type="bar"
                    name="WERTENTWICKLUNG NACH JAHREN (%)"
                    template={document?.template}
                  />
                </Box>
                <Box sx={styles.widget}>
                  <Typography
                    sx={styles.chartsLabel}
                    onClick={handleEditableItemClick('wertentwicklung_nach_jahren_label', EditType.Text)}
                  >
                    <span>-</span>
                    {pdf?.wertentwicklung_nach_jahren_label}
                  </Typography>
                </Box>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick(
                      'wertentwicklung_nach_perioden',
                      !pdf?.wertentwicklung_nach_perioden.labels ? EditType.Array : EditType.DataTable
                    )}
                  >
                    WERTENTWICKLUNG NACH PERIODEN
                  </Label>
                  <Stack direction="row" width="100%" mt={32} borderBottom="1px solid #a7a8ab">
                    {pdf?.wertentwicklung_nach_perioden.labels
                      ? pdf?.wertentwicklung_nach_perioden.labels.map((label, key: number) => (
                          <Box key={key} sx={{ flex: 1, textAlign: key === 0 ? 'left' : 'center' }}>
                            <Typography color="#000" sx={{ fontWeight: 'bold !important' }}>
                              {label}
                            </Typography>
                          </Box>
                        ))
                      : pdf?.wertentwicklung_nach_perioden?.map((item: any, key: number) => (
                          <Box key={key} sx={{ flex: 1, textAlign: key === 0 ? 'left' : 'center' }}>
                            <Typography color="#000" sx={{ fontWeight: 'bold !important' }}>
                              {item.label}
                            </Typography>
                          </Box>
                        ))}
                  </Stack>
                  <Stack
                    direction={pdf?.wertentwicklung_nach_perioden.values?.map ? 'column' : 'row'}
                    width="100%"
                    mt={1}
                    mx={-1}
                  >
                    {pdf?.wertentwicklung_nach_perioden.values?.map
                      ? pdf?.wertentwicklung_nach_perioden.values?.map((row, index) => (
                          <Stack key={index} direction="row" width="100%" mt={1} mx={-1}>
                            {row.map((cell: any, columnIndex) =>
                              pdf?.wertentwicklung_nach_perioden?.columns &&
                              pdf?.wertentwicklung_nach_perioden?.columns[columnIndex]?.type === 'avatar' ? (
                                <Box
                                  key={columnIndex}
                                  sx={{
                                    flex: 1,
                                    margin: '2px',
                                    px: '8px',
                                    background: '#D2D2D4',
                                    textAlign: 'left',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  <ListItemAvatar sx={{ justifyContent: 'left !important', width: '36px !important' }}>
                                    <img src={cell} style={{ width: 36, height: 36 }} alt="cell-avatar" />
                                  </ListItemAvatar>
                                </Box>
                              ) : (
                                <Box
                                  key={columnIndex}
                                  sx={{
                                    flex: 1,
                                    margin: '2px',
                                    px: '8px',
                                    background: '#D2D2D4',
                                    textAlign: 'left',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {cell?.linkUrl !== undefined ? (
                                    <a target="_blank" className={'list-link-text'} href={cell?.linkUrl}>
                                      <Typography color="#000">{cell?.linkText}</Typography>
                                    </a>
                                  ) : pdf?.wertentwicklung_nach_perioden?.columns &&
                                    pdf?.wertentwicklung_nach_perioden?.columns[columnIndex]?.showValueConfiguration ? (
                                      <NoMaxWidthTooltip
                                        arrow
                                        title={
                                          <Box sx={styles.configWrapper}>
                                            {cell.fields ? (
                                              cell.fields?.map((field, index) => (
                                                <Box
                                                  key={index}
                                                  sx={(theme) => styles.configField(theme, { type: field.type })}
                                                >
                                                  <span>
                                                    {field.type === 'text'
                                                      ? field.text
                                                      : `${field.table || '(table)'}.${field.field || '(field)'}`}
                                                  </span>
                                                </Box>
                                              ))
                                            ) : (
                                              <Typography color="#000">{cell}</Typography>
                                            )}
                                          </Box>
                                        }
                                      >
                                        <Box sx={styles.configuredColumn}>
                                          <Settings />
                                          Configured
                                        </Box>
                                      </NoMaxWidthTooltip>
                                  ) : typeof cell === 'string' ? (
                                    <Typography color="#000">{cell}</Typography>
                                  ) : (
                                    <Typography color="#000">{cell.text}</Typography>
                                  )}
                                </Box>
                              )
                            )}
                          </Stack>
                        ))
                      : pdf?.wertentwicklung_nach_perioden.map((item: any, key: number) => (
                          <Box
                            key={key}
                            sx={{
                              flex: 1,
                              margin: '2px',
                              px: '8px',
                              background: '#D2D2D4',
                              textAlign: 'left',
                              wordBreak: 'break-word',
                            }}
                          >
                            <Typography color="#000">{item.value}</Typography>
                          </Box>
                        ))}
                  </Stack>
                </Box>
                {pdf?.risikoindikatoren?.labels?.length > 0 && (
                  <Box sx={styles.widget}>
                    <Label
                      color={document?.template?.color}
                      onClick={handleEditableItemClick('risikoindikatoren', EditType.DataTable)}
                    >
                      RISIKOINDIKATOREN
                    </Label>
                    <Stack direction="row" width="100%" mt={32} borderBottom="1px solid #a7a8ab">
                      {pdf?.risikoindikatoren?.labels.map((item: any, key: number) => (
                        <Box key={key} sx={{ flex: 1, textAlign: key === 0 ? 'left' : 'center' }}>
                          <Typography sx={{ fontWeight: 'bold !important' }}>{item}</Typography>
                        </Box>
                      ))}
                    </Stack>
                    {pdf?.risikoindikatoren.values.map((row: any[], index: number) => (
                      <Stack key={index} direction="row" width="100%" mt={1} mx={-1}>
                        {row.map((cell: any, columnIndex) =>
                          pdf?.risikoindikatoren?.columns &&
                          pdf?.risikoindikatoren?.columns[columnIndex]?.type === 'avatar' ? (
                            <Box
                              key={columnIndex}
                              sx={{
                                flex: 1,
                                margin: '2px',
                                px: '8px',
                                background: '#D2D2D4',
                                textAlign: 'left',
                                wordBreak: 'break-word',
                              }}
                            >
                              <ListItemAvatar sx={{ justifyContent: 'left !important', width: '36px !important' }}>
                                <img src={cell} style={{ width: 36, height: 36 }} alt="cell-avatar" />
                              </ListItemAvatar>
                            </Box>
                          ) : (
                            <Box
                              key={columnIndex}
                              sx={{
                                flex: 1,
                                margin: '2px',
                                px: '8px',
                                background: '#D2D2D4',
                                textAlign: 'left',
                                wordBreak: 'break-word',
                              }}
                            >
                              {cell?.linkUrl !== undefined ? (
                                <a target="_blank" className={'list-link-text'} href={cell?.linkUrl}>
                                  <Typography color="#000">{cell?.linkText}</Typography>
                                </a>
                              ) : pdf?.risikoindikatoren?.columns &&
                                pdf?.risikoindikatoren?.columns[columnIndex]?.showValueConfiguration ? (
                                <Box sx={styles.configWrapper}>
                                  {cell.fields ? (
                                    cell.fields?.map((field, index) => (
                                      <NoMaxWidthTooltip
                                        arrow
                                        title={
                                          <Box sx={styles.configWrapper}>
                                            {cell.fields ? (
                                              cell.fields?.map((field, index) => (
                                                <Box
                                                  key={index}
                                                  sx={(theme) => styles.configField(theme, { type: field.type })}
                                                >
                                                  <span>
                                                    {field.type === 'text'
                                                      ? field.text
                                                      : `${field.table || '(table)'}.${field.field || '(field)'}`}
                                                  </span>
                                                </Box>
                                              ))
                                            ) : (
                                              <Typography color="#000">{cell}</Typography>
                                            )}
                                          </Box>
                                        }
                                      >
                                        <Box sx={styles.configuredColumn}>
                                          <Settings />
                                          Configured
                                        </Box>
                                      </NoMaxWidthTooltip>
                                    ))
                                  ) : (
                                    <Typography color="#000">{cell}</Typography>
                                  )}
                                </Box>
                              ) : typeof cell === 'string' ? (
                                <Typography color="#000">{cell}</Typography>
                              ) : (
                                <Typography color="#000">{cell.text}</Typography>
                              )}
                            </Box>
                          )
                        )}
                      </Stack>
                    ))}
                  </Box>
                )}
              </Grid>
            </Grid>
            <Typography color="#000" align="justify" fontSize={14}>
              1Wertentwicklungen in der Vergangenheit sind kein verlässlicher Indikator für die künftige
              Wertentwicklung. <br />
              2Gesamtkostenquote des durchschnittlichen Fondsvermögens per Geschäftsjahresende 31.12. (ohne
              Berücksichtigung <br />
              performanceabhängiger Vergütung)
            </Typography>
          </Card>
          <Card sx={styles.paper}>
            <Typography color="#949599" fontSize={24} fontWeight={600}>
              Werbematerial, kein investmentrechtliches Pfichtdokument
            </Typography>
            <Header pdf={pdf} editableItemClick={handleEditableItemClick} template={document?.template} />
            <Grid mt={30} container spacing={50}>
              <Grid item xs={5}>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('strategie', EditType.Text)}
                  >
                    STRATEGIE
                  </Label>
                  <Typography color="#000" fontSize={20} align="justify">
                    {pdf?.strategie}
                  </Typography>
                </Box>
                <Box sx={styles.widget}>
                  <Label color={document?.template?.color} onClick={handleEditableItemClick('chancen', EditType.Text)}>
                    CHANCEN
                  </Label>
                  <Typography color="#000" fontSize={20} align="justify">
                    {pdf?.chancen}
                  </Typography>
                </Box>
                <Box sx={styles.widget}>
                  <Label color={document?.template?.color} onClick={handleEditableItemClick('risiken', EditType.Text)}>
                    RISIKEN
                  </Label>
                  <Typography color="#000" fontSize={20} align="justify">
                    {pdf?.risiken}
                  </Typography>
                </Box>
                <Box sx={styles.widget}>
                  <Label color={document?.template?.color} onClick={handleEditableItemClick('ethik', EditType.Text)}>
                    ETHIK
                  </Label>
                  <Box>
                    <img src="/images/sustainalytics.png" alt="sustainalytics" />
                  </Box>
                  <Divider sx={styles.divider} />
                  <Typography color="#000" fontSize={20} align="justify">
                    {pdf?.ethik}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('gesamtportfolio', EditType.KeyValueArray)}
                  >
                    <LabelEditor
                      color={document?.template?.color}
                      value={
                        pdf.headings?.['gesamtportfolio'] || pdf.pdf_titles?.[0] || 'TOP-5-AKTIEN (GESAMTPORTFOLIO)'
                      }
                      onChange={(value) => handleSaveTitle('gesamtportfolio', value)}
                    />
                  </Label>
                  <Chart data={pdf?.gesamtportfolio} type="horizontal-bar" template={document?.template} />
                </Box>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('branchen', EditType.KeyValueArray)}
                  >
                    <LabelEditor
                      color={document?.template?.color}
                      value={pdf.headings?.['branchen'] || pdf.pie_titles?.[1] || 'BRANCHEN'}
                      onChange={(value) => handleSaveTitle('branchen', value)}
                    />
                  </Label>
                  <Chart data={pdf?.branchen} type="donut" />
                </Box>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('regionen', EditType.KeyValueArray)}
                  >
                    <LabelEditor
                      color={document?.template?.color}
                      value={pdf.headings?.['regionen'] || pdf.pie_titles?.[2] || 'REGIONEN'}
                      onChange={(value) => handleSaveTitle('regionen', value)}
                    />
                  </Label>
                  <Chart data={pdf?.regionen} type="donut" />
                </Box>
                <Box sx={styles.widget}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('marktkapitalisierung', EditType.KeyValueArray)}
                  >
                    <LabelEditor
                      color={document?.template?.color}
                      value={pdf.headings?.['marktkapitalisierung'] || pdf.pie_titles?.[3] || 'MARKTKAPITALISIERUNG'}
                      onChange={(value) => handleSaveTitle('marktkapitalisierung', value)}
                    />
                  </Label>
                  <Chart data={pdf?.marktkapitalisierung} type="donut" />
                </Box>
                {pdf?.pie_titles?.length === 5 && (
                  <Box sx={styles.widget}>
                    <Label
                      color={document?.template?.color}
                      onClick={handleEditableItemClick('pie_addition', EditType.KeyValueArray)}
                    >
                      <LabelEditor
                        color={document?.template?.color}
                        value={pdf.headings?.['pie_addition'] || pdf.pie_titles?.[4] || 'MARKTKAPITALISIERUNG'}
                        onChange={(value) => handleSaveTitle('pie_addition', value)}
                      />
                    </Label>
                    <Chart data={pdf?.pie_addition} type="donut" />
                  </Box>
                )}
              </Grid>
            </Grid>
            <Typography variant="caption" align="justify" fontSize={'10 !important'}>
              <span style={{ color: document?.template?.color || '#003f7a' }}>Disclaimer:</span> Die in diesem Factsheet
              angegebenen Fondsinformationen sind zu allgemeinen Informationszwecken erstellt worden. Sie ersetzen weder
              eigene Marktrecherchen noch sonstige rechtliche, steuerliche oder finanzielle Information oder Beratung.
              Der Kapitalwert und der Anlageertrag eines Investments in diesen Fonds wird ebenso Schwankungen
              unterliegen wie die Notierung der einzelnen Wertpapiere, in die der Fonds investiert, so dass erworbene
              Anteile bei der Rückgabe sowohl mehr, als auch weniger wert sein können, als zum Zeitpunkt der
              Anschaffung. Wertentwicklungen in der Vergangenheit sind kein verlässlicher Indikator für die künftige
              Wertentwicklung. Investmentfonds unterliegen marktbedingten Kursschwankungen, die zu Verlusten, bis hin
              zum Totalverlust des eingesetzten Kapitals, führen können. Es wird ausdrücklich auf die Risikohinweise des
              Verkaufsprospektes verwiesen. Es kann keine Zusicherung gemacht werden, dass die Ziele der Anlagepolitik
              erreicht werden. Alle Meinungsaussagen geben eine aktuelle Einschätzung wieder, die ohne vorherige
              Ankündigung geändert werden kann. Ausführliche Hinweise zu Chancen und Risiken entnehmen Sie bitte dem
              Verkaufsprospekt. Grundlage des Kaufes ist der aktuelle Verkaufsprospekt. Diesem können Sie die für die
              jeweiligen Vertriebsgebiete relevanten Besonderheiten und Spezifika entnehmen, so ist beispielsweise der
              Vertreter einiger Fonds in der Schweiz die FIRST INDEPENDENT FUND SERVICES AG, Klausstrasse 33, CH-8008
              Zürich, und die Zahlstelle InCore Bank AG, Fonds/Zahlstellen, Wiesenstrasse 17, CH-8952 Schlieren/Zürich.
              Der aktuelle Verkaufsprospekt, das Verwaltungsreglement und die Berichte sowie die wesentlichen
              Anlegerinformationen – erhalten Sie kostenlos in deutscher Sprache bei der Vertriebsstelle, der Depotbank,
              dem Schweizer Vertreter, der Verwaltungsgesellschaft sowie unter www.shareholdervalue.de. Die Shareholder
              Value Management AG betreibt die Anlageberatung unter Haftung der NFS Netfonds Financial Service GmbH,
              Heidenkampsweg 73, 20097 Hamburg. Die Shareholder Value Management AG ist als vertraglich gebundener
              Vermittler der NFS Netfonds Financial Service GmbH in das öffentliche Register eingetragen, das von der
              Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) im Internet geführt wird. Das Register können Sie
              einsehen auf www.bafin.de. Sofern nicht anders angegeben, entsprechen alle dargestellten Wertentwicklungen
              der Bruttowertentwicklung. Die Bruttowertentwicklung (BVI-Methode) berücksichtigt bereits alle auf
              Fondsebene anfallenden Kosten und geht von einer Wiederanlage eventueller Ausschüttung aus. Dieses
              Factsheet ist für die allgemeine Verbreitung bestimmt und dient ausschließlich zu Informationszwecken. Es
              ist weder als Angebot zum Erwerb, noch als Anlageberatung auszulegen und bezieht sich nicht auf die
              spezifischen Anlageziele, die finanzielle Situation bzw. auf etwaige Anforderungen von Personen, denen
              dieses Factsheet ausgehändigt wird. Obwohl große Sorgfalt darauf verwendet wurde sicherzustellen, dass die
              in diesem Factsheet enthaltenen Informationen korrekt sind, kann ebenso wenig eine Verantwortung für
              Fehler oder Auslassungen irgendwelcher Art übernommen werden wie für alle Arten von Handlungen, die auf
              diesen basieren. Die ausgegebenen Anteile dieses Fonds dürfen nur in solchen Rechtsordnungen zum Kauf
              angeboten oder verkauft werden, in denen ein solches Angebot oder ein solcher Verkauf zulässig ist. So
              dürfen die Anteile dieses Fonds weder innerhalb der USA noch an oder für Rechnung von US-Staatsbürgern
              oder in den USA ansässigen US-Personen zum Kauf angeboten oder an diese verkauft werden. Dieses Dokument
              und die in ihm enthaltenen Informationen dürfen nicht in den USA verbreitet werden.
            </Typography>
          </Card>

          {document?.template?._id === '630cebfecb9741ec6761744d' && (
            <Card sx={styles.paper}>
              <Typography color="#949599" fontSize={24}>
                Werbematerial, kein investmentrechtliches Pfichtdokument
              </Typography>
              <Header pdf={pdf} editableItemClick={handleEditableItemClick} template={document?.template} />
              <Box sx={styles.intro} onClick={handleEditableItemClick('intro_third', EditType.Intro)}>
                <Grid container spacing={20} sx={{ pb: 20 }}>
                  <Grid item xs={2}>
                    <Box sx={styles.introImage}>
                      <img src="/images/tree.png" alt="intro" />
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography align="justify" color="#000000" fontSize={20} fontWeight={600}>
                      {pdf?.intro_third && pdf?.intro_third[0]}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography align="justify" color="#000000" fontSize={20} fontWeight={600}>
                      {pdf?.intro_third && pdf?.intro_third[1]}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Label
                  color={document?.template?.color}
                  onClick={handleEditableItemClick('business_table', EditType.Table)}
                >
                  KONTROVERSE GESCHÄFTSFELDER
                </Label>
                <Grid container spacing={0} columns={100} sx={styles.businessTable}>
                  <Grid item xs={4} sx={styles.businessTableHeaderCell} />
                  <Grid item xs={25} sx={styles.businessTableHeaderCell}>
                    <Typography>Ausschlusskriterien</Typography>
                  </Grid>
                  <Grid item xs={12} sx={styles.businessTableHeaderCell}>
                    <Typography>Kein Umsatz im Geschäftsfeld ❶</Typography>
                  </Grid>
                  <Grid item xs={8} sx={styles.businessTableHeaderCell}>
                    <Typography>{'Umsatz < 5 % ❷'}</Typography>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={4} sx={styles.businessTableHeaderCell} />
                  <Grid item xs={25} sx={styles.businessTableHeaderCell}>
                    <Typography>Ausschlusskriterien</Typography>
                  </Grid>
                  <Grid item xs={12} sx={styles.businessTableHeaderCell}>
                    <Typography>Kein Umsatz im Geschäftsfeld ❷</Typography>
                  </Grid>
                  <Grid item xs={8} sx={styles.businessTableHeaderCell}>
                    <Typography>{'Umsatz < 5 % ❷'}</Typography>
                  </Grid>

                  {pdf?.business_table
                    ?.reduce(
                      (array, item, index) =>
                        index % 2 === 0
                          ? [
                              ...array,
                              [[getEsgItemImage(item, index) || '', item.label, item.no_turnover, item.sales_volume]],
                            ]
                          : [
                              ...array.slice(0, array.length - 1),
                              [
                                array[array.length - 1][0],
                                [getEsgItemImage(item, index) || '', item.label, item.no_turnover, item.sales_volume],
                              ],
                            ],
                      []
                    )
                    .map((data, rowIndex) => (
                      <>
                        <Grid item xs={4} sx={styles.businessTableBodyCell}>
                          <img src={data[0][0]} alt="intro" />
                        </Grid>
                        <Grid item xs={25} sx={styles.businessTableBodyCell}>
                          <Typography>{data[0][1]}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={styles.businessTableBodyCell}>
                          <Typography>{getValueByLabel(rowIndex * 2, 0)}</Typography>
                        </Grid>
                        <Grid item xs={8} sx={styles.businessTableBodyCell}>
                          <Typography>{getValueByLabel(rowIndex * 2, 1)}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={styles.businessTableBodyCell} />
                        {data[1] && (
                          <>
                            <Grid item xs={4} sx={styles.businessTableBodyCell}>
                              <img src={data[1][0]} alt="intro" />
                            </Grid>
                            <Grid item xs={25} sx={styles.businessTableBodyCell}>
                              <Typography>{data[1][1]}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={styles.businessTableBodyCell}>
                              <Typography>{getValueByLabel(rowIndex * 2 + 1, 0)}</Typography>
                            </Grid>
                            <Grid item xs={8} sx={styles.businessTableBodyCell}>
                              <Typography>{getValueByLabel(rowIndex * 2 + 1, 1)}</Typography>
                            </Grid>
                          </>
                        )}
                      </>
                    ))}
                </Grid>
              </Box>

              <Box sx={styles.subIntro} onClick={handleEditableItemClick('table_intro', EditType.Intro)}>
                <Grid container spacing={10} mt={8} mb={40}>
                  <Grid item xs={6}>
                    <Typography>{pdf?.table_intro && pdf?.table_intro[0]}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{pdf?.table_intro && pdf?.table_intro[1]}</Typography>
                  </Grid>
                </Grid>
              </Box>

              <Divider />
              <Grid container spacing={10} mt={8} mb={20}>
                <Grid item xs={6}>
                  <Label
                    color={document?.template?.color}
                    onClick={handleEditableItemClick('geschaftsparktiken', EditType.KeyValueArray)}
                    hideDivider
                  >
                    KONTROVERSE GESCHÄFTSPRAKTIKEN
                  </Label>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      onClick={handleEditableItemClick('violation', EditType.Text)}
                      sx={{ '&:hover': { background: '#c3c3c3', cursor: 'pointer' } }}
                    >
                      <img style={{ width: '40px', marginRight: '10px' }} src="/images/scale.png" alt="scale" />
                      <Typography>{pdf?.violation}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      onClick={handleEditableItemClick('pollution', EditType.Text)}
                      sx={{ '&:hover': { background: '#c3c3c3', cursor: 'pointer' } }}
                    >
                      <img style={{ width: '40px', marginRight: '10px' }} src="/images/recycle.png" alt="recycle" />
                      <Typography>{pdf?.pollution}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Chart
                template={document?.template}
                data={pdf?.geschaftsparktiken}
                type="bar"
                name="WERTENTWICKLUNG NACH JAHREN (%)"
              />

              <Box sx={styles.subIntro} onClick={handleEditableItemClick('chart_intro', EditType.Intro)}>
                <Grid container spacing={10} mt={8} mb={40}>
                  <Grid item xs={6}>
                    <Typography>{pdf?.chart_intro && pdf?.chart_intro[0]}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{pdf?.chart_intro && pdf?.chart_intro[1]}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Label color={document?.template?.color} onClick={handleEditableItemClick('logos', EditType.Logo)}>
                  PARTNER & ZERTIFIZIERUNGEN
                </Label>
                <Grid container spacing={10} mt={8} columns={100}>
                  {pdf?.logos?.map((logo, _, array) => (
                    <Grid item xs={100 / array.length}>
                      <img style={{ width: 200 }} src={`${API_SERVER}/public/${logo.path}`} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Card>
          )}

          {document?.template?._id === '630cebfecb9741ec6761744d' && (
            <Card sx={styles.paper}>
              <Typography color="#949599" fontSize={24}>
                Werbematerial, kein investmentrechtliches Pfichtdokument
              </Typography>
              <Header pdf={pdf} editableItemClick={handleEditableItemClick} template={document?.template} />

              <Divider sx={{ mt: 40, mb: 5, borderColor: 'black' }} />
              <Typography variant="h6" color="#946A4E" textAlign="center">
                ANSPRECHPARTNER
              </Typography>
              <Divider sx={styles.blackDivider} />

              <Typography mt={16} textAlign="center" variant="body1">
                <b>Bleiben Sie mit uns in Kontakt!</b> Bei Fragen zu unseren Fonds rufen Sie uns gerne an.
              </Typography>

              <Box mx={100} mt={50}>
                <Grid container columnSpacing={30} rowSpacing={30} mt={20}>
                  {pdf?.contact_fields?.map((contact) => (
                    <Grid xs={12} md={4} display="flex" justifyContent="center" mb={16}>
                      <Box
                        sx={styles.contact}
                        onClick={handleEditableItemClick('contact_fields', EditType.Contact, contact?._id)}
                      >
                        <img
                          style={{ width: '100%' }}
                          src={
                            typeof contact?.image === 'string'
                              ? contact.image?.includes('https')
                                ? contact.image
                                : `${API_SERVER}/${contact.image}`
                              : URL.createObjectURL(contact?.image)
                          }
                        />
                        <Typography variant="h6" color="#946A4E">
                          {contact.name}
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {contact.role}
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {contact.second_role}
                        </Typography>
                        <Typography variant="body1">{contact.email}</Typography>
                        <Typography variant="body1">{contact.tel}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Typography mt={30} variant="h6" color="#8F8987" textAlign="center">
                Shareholder Value in den Medien: Besuchen Sie uns online. Diskutieren Sie mit.
              </Typography>
              <Box sx={styles.socialSites} onClick={handleEditableItemClick('social_sites', EditType.Logo)}>
                {pdf?.social_sites.map((site) => (
                  <Box mx={20}>
                    <Link href={site.link} onClick={(e) => e.stopPropagation()}>
                      <Box sx={styles.site}>
                        <img
                          style={{ width: 72 }}
                          src={site.path?.includes('https') ? site.path : `${API_SERVER}/public/${site.path}`}
                        />
                      </Box>
                    </Link>
                  </Box>
                ))}
              </Box>
              <Divider sx={{ mb: 10 }} />
              <Typography color="#000" mt={10} variant="caption" align="justify">
                <span style={{ color: '#003f7a' }}>Disclaimer:</span> Die in diesem Factsheet angegebenen
                Fondsinformationen sind zu allgemeinen Informationszwecken erstellt worden. Sie ersetzen weder eigene
                Marktrecherchen noch sonstige rechtliche, steuerliche oder fnanzielle Information oder Beratung. Der
                Kapitalwert und der Anlageertrag eines Investments in diesen Fonds wird ebenso Schwankungen unterliegen
                wie die Notierung der einzelnen Wertpapiere, in die der Fonds investiert, so dass erworbene Anteile bei
                der Rückgabe sowohl mehr, als auch weniger wert sein können, als zum Zeitpunkt der Anschaffung.
                Wertentwicklungen in der Vergangenheit sind kein verlässlicher Indikator für die künftige
                Wertentwicklung. Investmentfonds unterliegen marktbedingten Kursschwankungen, die zu Verlusten, bis hin
                zum Totalverlust des eingesetzten Kapitals, führen können. Es wird ausdrücklich auf die Risikohinweise
                des Verkaufsprospektes verwiesen. Es kann keine Zusicherung gemacht werden, dass die Ziele der
                Anlagepolitik erreicht werden. Alle Meinungsaussagen geben eine aktuelle Einschätzung wieder, die ohne
                vorherige Ankündigung geändert werden kann. Ausführliche Hinweise zu Chancen und Risiken entnehmen Sie
                bitte dem Verkaufsprospekt. Grundlage des Kaufes ist der aktuelle Verkaufsprospekt. Diesem können Sie
                die für die jeweiligen Vertriebsgebiete relevanten Besonderheiten und Spezifka entnehmen, so ist
                beispielsweise der Vertreter einiger Fonds in der Schweiz die ACOLIN Fund Services AG,
                Leutschenbachstrasse 50, CH-8050 Zürich, und die Zahlstelle InCore Bank AG, Fonds/Zahlstellen,
                Wiesenstrasse 17, CH-8952 Schlieren/Zürich. Der aktuelle Verkaufsprospekt, das Verwaltungsreglement und
                die Berichte sowie die wesentlichen Anlegerinformationen – erhalten Sie kostenlos in deutscher Sprache
                bei der Vertriebsstelle, der Depotbank, dem Schweizer Vertreter, der Verwaltungsgesellschaft sowie unter
                www.shareholdervalue.de. Die Shareholder Value Management AG betreibt die Anlageberatung unter Haftung
                der NFS Netfonds Financial Service GmbH, Heidenkampsweg 73, 20097 Hamburg. Die Shareholder Value
                Management AG ist als vertraglich gebundener Vermittler der NFS Netfonds Financial Service GmbH in das
                öffentliche Register eingetragen, das von der Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) im
                Internet geführt wird. Das Register können Sie einsehen auf www.bafn.de. Sofern nicht anders angegeben,
                entsprechen alle dargestellten Wertentwicklungen der Bruttowertentwicklung. Die Bruttowertentwicklung
                (BVI-Methode) berücksichtigt bereits alle auf Fondsebene anfallenden Kosten und geht von einer
                Wiederanlage eventueller Ausschüttung aus. Dieses Factsheet ist für die allgemeine Verbreitung bestimmt
                und dient ausschließlich zu Informationszwecken. Es ist weder als Angebot zum Erwerb, noch als
                Anlageberatung auszulegen und bezieht sich nicht auf die spezifschen Anlageziele, die fnanzielle
                Situation bzw. auf etwaige Anforderungen von Personen, denen dieses Factsheet ausgehändigt wird. Obwohl
                große Sorgfalt darauf verwendet wurde sicherzustellen, dass die in diesem Factsheet enthaltenen
                Informationen korrekt sind, kann ebenso wenig eine Verantwortung für Fehler oder Auslassungen
                irgendwelcher Art übernommen werden wie für alle Arten von Handlungen, die auf diesen basieren. Die
                ausgegebenen Anteile dieses Fonds dürfen nur in solchen Rechtsordnungen zum Kauf angeboten oder verkauft
                werden, in denen ein solches Angebot oder ein solcher Verkauf zulässig ist. So dürfen die Anteile dieses
                Fonds weder innerhalb der USA noch an oder für Rechnung von US-Staatsbürgern oder in den USA ansässigen
                US-Personen zum Kauf angeboten oder an diese verkauft werden. Dieses Dokument und die in ihm enthaltenen
                Informationen dürfen nicht in den USA verbreitet werden.
              </Typography>
            </Card>
          )}
        </Stack>
      )}

      {visibleEditModal === EditType.Logo && (
        <LogosEditModal
          title={editField}
          open={!!visibleEditModal}
          data={editData}
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}

      {visibleEditModal === EditType.Intro && (
        <TextEditModal
          title={editField}
          open={!!visibleEditModal}
          data={editData}
          type="intro"
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}

      {visibleEditModal === EditType.Text && (
        <TextEditModal
          title={editField}
          open={!!visibleEditModal}
          data={editData}
          type="text"
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
      {visibleEditModal === EditType.Date && (
        <TextEditModal
          title={editField}
          open={!!visibleEditModal}
          data={editData}
          type="date"
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
      {visibleEditModal === EditType.Object && (
        <ObjectEditModal
          title={editField}
          open={!!visibleEditModal}
          data={editData}
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
      {visibleEditModal === EditType.Array && (
        <ArrayEditModal
          title={editField}
          open={!!visibleEditModal}
          data={editData}
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
      {visibleEditModal === EditType.KeyValueArray && (
        <ArrayEditModal
          title={pdf.headings?.[editField] || editField}
          open={!!visibleEditModal}
          data={editData}
          labelEditable
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
      {visibleEditModal === EditType.Table && (
        <BusinessTableEditModal
          open={!!visibleEditModal}
          data={editData}
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
      {visibleEditModal === EditType.Contact && (
        <ContactEditModal
          title={editField}
          open={!!visibleEditModal}
          data={editData}
          selectedId={editFieldId}
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSaveContact}
        />
      )}
      {visibleEditModal === EditType.DataTable && (
        <DataTableEditModal
          title={editField}
          open={!!visibleEditModal}
          data={editData}
          loading={customizeLoading}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
      {previewPdf && <PreviewDocDialog open={!!previewPdf} previewPdf={previewPdf} onClose={handleClosePreviewModal} />}
    </>
  );
};
