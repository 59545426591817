// Dependencies
import React, { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  OutlinedInput,
  IconButton,
  FormControlLabel,
  Switch,
  Tooltip,
  Popover,
} from '@mui/material';
import { Add, Remove, Settings } from '@mui/icons-material';
import styles from './styles';
import { AutomaticCellConfigDialog } from '../../../../../../components';
import { ColorPicker } from 'react-color-palette';
import 'react-color-palette/css';
import { hexToColor } from './common';

type BlocksProps = {
  id: number;
  content: any;
  preview: boolean;
  onChange: (id: number, contentData: any) => void;
};

const colors = ['#0461b8', '#165b9d', '#275681', '#395066', '#4a4a4a'];

// Export template panel
export const Blocks: FC<BlocksProps> = ({ id, content, preview, onChange }) => {
  const [selectedCellPos, setSelectedCellPos] = useState<'label' | 'value' | null>(null);
  const [selectedColorCellPosIndex, setSelectedCellPosIndex] = useState<number | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: number) => {
    setAnchorEl(e.currentTarget);
    setSelectedCellPosIndex(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCellPosIndex(undefined);
  };

  const open = Boolean(selectedColorCellPosIndex !== undefined);
  const openId = open ? 'simple-popover' : undefined;

  // Change data handler
  const handleChangeData = (e, index) => {
    if (Array.isArray(content)) {
      onChange(
        id,
        content.map((p, i) => (index === i ? { ...p, [e.target.name]: e.target.value } : p))
      );
    } else {
      onChange(id, {
        ...content,
        data: content.data.map((p, i) => (index === i ? { ...p, [e.target.name]: e.target.value } : p)),
      });
    }
  };

  // Add data handler
  const handleAddData = () => {
    if (Array.isArray(content)) {
      onChange(id, [...content, { label: '', value: 0 }]);
    } else {
      onChange(id, { ...content, data: [...content.data, { label: '', value: 0 }] });
    }
  };

  // Change remove item
  const handleRemoveLine = (index: number) => {
    if (Array.isArray(content)) {
      onChange(id, content.slice(0, index).concat(content.slice(index + 1)));
    } else {
      onChange(id, { ...content, data: content.data.slice(0, index).concat(content.data.slice(index + 1)) });
    }
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(content)) {
      onChange(id, { data: content, title: e.target.value });
    } else {
      onChange(id, { ...content, title: e.target.value });
    }
  };

  const handleChangeDynamicStatus = (e: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(content)) {
      onChange(id, {
        data: [{ label: '', value: '' }],
        isDynamic: e.target.checked,
        countOfRow: 'All',
        colors: undefined,
      });
    } else {
      onChange(id, {
        ...content,
        data: [{ label: '', value: '' }],
        isDynamic: e.target.checked,
        countOfRow: 'All',
        colors: undefined,
      });
    }
  };

  const handleChangeSubTitle = (e: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(content)) {
      onChange(id, { data: content, subtitle: e.target.value });
    } else {
      onChange(id, { ...content, subtitle: e.target.value });
    }
  };

  const handleChangeCountOfRole = (value: string | number) => {
    if (Array.isArray(content)) {
      onChange(id, { data: content, countOfRow: value, content, colors: value === 'All' ? [] : colors });
    } else {
      onChange(id, { ...content, countOfRow: value, colors: value === 'All' ? [] : colors });
    }
  };

  const handleChangeDefaultColor = (value: string) => {
    const colors = [...(content.colors || [])];
    colors[selectedColorCellPosIndex || 0] = value;
    if (Array.isArray(content)) {
      onChange(id, { data: content, colors: colors, defaultColors: undefined });
    } else {
      onChange(id, { ...content, colors: colors, defaultColors: undefined });
    }
  };

  const handleOpenCellConfigDialog = (field: 'label' | 'value') => {
    setSelectedCellPos(field);
  };

  const handleCloseCellConfigDialog = () => {
    setSelectedCellPos(null);
  };

  const handleSetCellConfig = (data: any) => {
    if (selectedCellPos) {
      if (Array.isArray(content)) {
        onChange(
          id,
          content.map((p, i) => (0 === i ? { ...p, [selectedCellPos]: data } : p))
        );
      } else {
        onChange(id, {
          ...content,
          data: content.data.map((p, i) => (0 === i ? { ...p, [selectedCellPos]: data } : p)),
        });
      }
    }

    setSelectedCellPos(null);
  };
  console.log(content);
  // Return template panel
  return (
    <Stack>
      <Box>
        {preview ? (
          <>{preview}</>
        ) : (
          <>
            <Grid container>
              <Grid item xs={6}>
                <Box sx={{ mb: 16 }}>
                  <Typography variant="h5">Blocks</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={16} mb={16}>
                  <Typography sx={{ width: '70px' }} variant="subtitle1">
                    Title
                  </Typography>
                  <OutlinedInput
                    sx={{ width: '300px' }}
                    name="title"
                    type="string"
                    value={content.title || ''}
                    onChange={handleChangeTitle}
                  />
                </Box>
                <Box display="flex" alignItems="center" gap="16px" mb={16}>
                  <Typography sx={{ width: '70px' }} variant="subtitle1">
                    Subtitle
                  </Typography>
                  <OutlinedInput
                    sx={{ width: '300px' }}
                    name="subtitle"
                    type="string"
                    value={content.subtitle || ''}
                    onChange={handleChangeSubTitle}
                  />
                </Box>
                <FormControlLabel
                  label="Dynamic Content?"
                  sx={styles.switch}
                  control={
                    <Switch checked={content.isDynamic} color="secondary" onChange={handleChangeDynamicStatus} />
                  }
                />
                {content.isDynamic && (
                  <Box display="flex" alignItems="center" gap={12}>
                    <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap', mr: 8 }}>
                      Count of Rows
                    </Typography>
                    <Box display="flex">
                      {new Array(5).fill(0).map((_, i) => (
                        <Box
                          key={i}
                          sx={(theme) =>
                            styles.rowCountItem(theme, {
                              isFirst: i === 0,
                              isLast: false,
                              active: (content.countOfRow || 'All') === (i + 1) * 5,
                            })
                          }
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          width={40}
                          height={40}
                          onClick={() => handleChangeCountOfRole((i + 1) * 5)}
                        >
                          {(i + 1) * 5}
                        </Box>
                      ))}
                      <Box
                        sx={(theme) =>
                          styles.rowCountItem(theme, {
                            isFirst: false,
                            active: (content.countOfRow || 'All') === 'All',
                            isLast: true,
                          })
                        }
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width={40}
                        height={40}
                        onClick={() => handleChangeCountOfRole('All')}
                      >
                        All
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
              {content.isDynamic && content.countOfRow !== 'All' ? (
                <Grid item xs={6}>
                  <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap', mr: 8 }}>
                    Color Theme Preview:
                  </Typography>
                  {new Array(content.countOfRow / 5).fill(0).map((_, rowInd) => (
                    <Box key={rowInd} display="flex">
                      {new Array(5).fill(0).map((_, colInd) => (
                        <Box
                          key={colInd}
                          onClick={(e) => handleClick(e, rowInd * 5 + colInd)}
                          style={{ backgroundColor: (content.colors || colors)[rowInd * 5 + colInd] }}
                          sx={(theme) =>
                            styles.colorPickerItem(theme, {
                              isFirst: colInd === 0,
                              isFirstRow: rowInd === 0,
                              isFirstCellOnFirstRow: colInd === 0 && rowInd === 0,
                              isFirstCellOnLastRow: colInd === 0 && rowInd === content.countOfRow / 5 - 1,
                              isLastCellOnLastRow: colInd === 4 && rowInd === content.countOfRow / 5 - 1,
                              isLastCellOnFirstRow: colInd === 4 && rowInd === 0,
                            })
                          }
                        ></Box>
                      ))}
                    </Box>
                  ))}
                  <Popover
                    id={openId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <ColorPicker
                      color={hexToColor((content.colors || colors)[selectedColorCellPosIndex || 0] || '#ffffff')}
                      onChange={(e) => {
                        handleChangeDefaultColor(e.hex);
                      }}
                      height={150}
                    />
                  </Popover>
                </Grid>
              ) : null}
            </Grid>

            {content.isDynamic ? (
              <Box>
                {content &&
                  (Array.isArray(content) ? content : content.data).map((item, index) => (
                    <React.Fragment key={`${item.id}_${index}`}>
                      <Grid container>
                        <Grid item xs={12} sm={5.5}>
                          <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>
                            Label:
                          </Typography>
                          <Box sx={styles.cellConfigInput}>
                            <Box sx={styles.configWrapper}>
                              {content.data[0].label.fields?.map((field: any, index) => (
                                <Box key={index} sx={(theme) => styles.configField(theme, { type: field.type })}>
                                  <Typography variant="subtitle2">
                                    {field.type === 'text'
                                      ? field.text
                                      : `${field.table || '(table)'}.${field.field || '(field)'}`}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                            <Tooltip title="Cell Config">
                              <IconButton onClick={() => handleOpenCellConfigDialog('label')}>
                                <Settings />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={5.5}>
                          <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>
                            Value:
                          </Typography>
                          <Box sx={styles.cellConfigInput}>
                            <Box sx={styles.configWrapper}>
                              {content.data[0].value.fields?.map((field: any, index) => (
                                <Box key={index} sx={(theme) => styles.configField(theme, { type: field.type })}>
                                  <Typography variant="subtitle2">
                                    {field.type === 'text'
                                      ? field.text
                                      : `${field.table || '(table)'}.${field.field || '(field)'}`}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                            <Tooltip title="Cell Config">
                              <IconButton onClick={() => handleOpenCellConfigDialog('value')}>
                                <Settings />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
              </Box>
            ) : (
              <Grid container spacing={30}>
                {content &&
                  (Array.isArray(content) ? content : content.data).map((item, index) => (
                    <React.Fragment key={`${item.id}_${index}`}>
                      <Grid item xs={12} sm={5.5}>
                        <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>
                          Label:
                        </Typography>
                        <OutlinedInput
                          name="label"
                          type="string"
                          fullWidth
                          value={item.label || ''}
                          onChange={(e) => handleChangeData(e, index)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5.5}>
                        <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>
                          Value:
                        </Typography>
                        <OutlinedInput
                          name="value"
                          type="number"
                          fullWidth
                          value={item.value || ''}
                          onChange={(e) => handleChangeData(e, index)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={1} display="flex">
                        <IconButton size="medium" sx={{ mt: 'auto' }} onClick={() => handleRemoveLine(index)}>
                          <Remove />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ))}
                <Grid item container xs={12} sm={6} md={4} spacing={10}>
                  <Grid item xs={12} sm={6}>
                    <Button fullWidth startIcon={<Add />} onClick={handleAddData}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {selectedCellPos && (
          <AutomaticCellConfigDialog
            defaultConfig={content.data[0].label?.fields ? content.data[0].label?.fields[0].table : undefined}
            config={content?.data[0][selectedCellPos]}
            open={!!selectedCellPos}
            onClose={handleCloseCellConfigDialog}
            onConfirm={handleSetCellConfig}
          />
        )}
      </Box>
    </Stack>
  );
};
